import clsx from "clsx";
import dayjs from "dayjs";
import { ChangeEvent } from "react";
import { useFormControlContext } from "@/components/FormControlContext/useFormControlContext";
import { DateInput } from "@/components/inputs/DateInput/DateInput";
import { Input } from "@/components/inputs/Input/Input";
import { SingleValidationError } from "@/util/customHooks/validation/useValidation";
import { DateLike } from "@/util/timeUtils";
import "./dateTimeInput.scss";

export type DateTimeInputProps = {
    disabled?: boolean;
    value?: DateLike | null;
    onValueChange?: (value: Date) => void;
    min?: DateLike | null;
    max?: DateLike | null;

    error?: SingleValidationError;

    className?: string;
    classNames?: {
        root?: string;
        date?: string;
        time?: string;
    };
};

export const DateTimeInput = (props: DateTimeInputProps) => {
    const { id } = useFormControlContext();

    const getMinRefDate = (): Date | null => {
        if (props.value) {
            return dayjs(props.value).toDate();
        } else if (props.min) {
            return dayjs(props.min).toDate();
        }

        return null;
    };

    const handleDateChange = (date: Date | null) => {
        if (date === null) {
            return;
        }

        const newDate = dayjs(date);
        const refDate = getMinRefDate();

        const updatedDateTime = refDate
            ? dayjs(refDate).year(newDate.year()).month(newDate.month()).date(newDate.date())
            : newDate;

        if (!updatedDateTime.isValid()) {
            return;
        }
        props.onValueChange?.(updatedDateTime.toDate());
    };

    const handleTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (!value) {
            return;
        }

        const newTime = dayjs(value, "HH:mm");
        const refDate = getMinRefDate();

        const updatedDateTime = refDate
            ? dayjs(refDate).hour(newTime.hour()).minute(newTime.minute()).startOf("second")
            : newTime;

        props.onValueChange?.(updatedDateTime.toDate());
    };

    return (
        <div className={clsx("date-time-input", props.className, props.classNames?.root)}>
            <DateInput
                id={id}
                disabled={props.disabled}
                className={clsx("date-time-input__date", props.classNames?.date)}
                error={props.error}
                placeholder="Datum"
                value={props.value}
                onChange={handleDateChange}
                min={props.min ? dayjs(props.min).toDate() : undefined}
                max={props.max ? dayjs(props.max).toDate() : undefined}
            />

            <Input
                disabled={props.disabled}
                className={clsx("date-time-input__time", props.classNames?.time)}
                error={props.error}
                type="time"
                value={props.value ? dayjs(props.value).format("HH:mm") : ""}
                onChange={handleTimeChange}
            />
        </div>
    );
};
